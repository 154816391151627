import iceBanner from '@/assets/ice-2024-banner.png';

export default [
  {
    title: 'ICE London',
    schedule: '06 - 08 February 2024',
    location: 'London',
    image: '/images/events/ice-london-2024.png',
    url: '/#contact',
    bannerImage: iceBanner,
  },
  {
    title: 'London IGB Affiliate',
    schedule: '06 - 09 February 2024',
    location: 'London',
    image: '/images/events/igb-affiliate-london-2024.png',
    url: '/#contact',
    bannerImage: iceBanner,
  },
  {
    title: 'SBC Summit Rio',
    schedule: '05 - 07 March 2024',
    location: 'Rio de Janeiro, Brazil',
    url: '/#contact',
    image: '/images/events/sbc-summit-rio-2024.png',
  },
  {
    title: 'SiGMA Americas',
    schedule: '23 - 25 April 2024',
    location: 'São Paulo, Brazil',
    url: '/#contact',
    image: '/images/events/sigmas-americas-2024.png',
  },
];
